export type LoadingStatus =
    { loading: false; loaded: false, error: false } |
    { loading: true; loaded: false, error: false } |
    { loading: false; loaded: true, error: false } |
    { loading: false; loaded: false, error: true };

export interface ILoadingStatus {
    DEFAULT: LoadingStatus;

    LOADING: LoadingStatus;

    LOADED: LoadingStatus;

    ERROR: LoadingStatus;
}

export const LOADING_STATUS: ILoadingStatus = {
    DEFAULT: {loading: false, loaded: false, error: false},
    LOADING: {loading: true, loaded: false, error: false},
    LOADED: {loading: false, loaded: true, error: false},
    ERROR: {loading: false, loaded: false, error: true},
};
